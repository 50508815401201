
export const json =  {
   "cookieName": "myuniquesurveyid",
   "locale": "ru",
   "title": " ",
   "description": "Онлайн опрос",
   "logoWidth": 60,
   "logoHeight": 60,
   "completedHtml": {
    "ru": "<h3>Это были все наши вопросы. Большое спасибо за Ваши ответы!</h3>"
   },
   "completedBeforeHtml": "<h3>Это были все наши вопросы. Большое спасибо за Ваши ответы! </h3>",
   "completedHtmlOnCondition": [
    {
     "expression": "({Q02} = 'none' or {Q02} < 30 or {Q03} = 98 or {Q04.3} <> 2 or {Q05} = 1 or {Q05} = 2 or {Q06} = 3 or {Q06} = 4",
     "html": "<h4> Благодарим Вас за участие в опросе! К сожалению, Вы не прошли критерии отбора </h4>"
    }
   ],
   "pages": [
    {
     "name": "page1",
     "elements": [
      {
       "type": "html",
       "name": "Q00_",
       "hideNumber": true,
       "html": "<table><body><row><td></td><td style='padding:0px'><b>Добрый день! Мы проводим опрос среди россиян, чтобы понять, какие способы они используют для поддержания своего здоровья. Обращаем Ваше внимание, что в соответствии со стандартами проведения маркетинговых исследований, ВСЕ Ваши ответы являются СТРОГО КОНФИДЕНЦИАЛЬНЫМИ, и будут использоваться после статистической обработки только в обобщенном виде без указания какой-либо персональной информации. Все ваши ответы будут использоваться только в исследовательских целях. Ваше участие в исследовании является добровольным. Заполнение опроса займет 40-45 минут.</b>  <a></a></td></row></body></table>"
      },
      {
       "type": "radiogroup",
       "name": "Q01",
       "title": "Q1. Укажите, пожалуйста, Ваш пол",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Мужской"
        },
        {
         "value": "2",
         "text": "Женский"
        }
       ]
      },
      {
       "type": "dropdown",
       "name": "Q02",
       "title": "Q2. Пожалуйста, отметьте Ваш возраст.",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
        "98",
        "99",
        "100"
       ],
       "showNoneItem": true,
       "noneText": {
        "ru": "Не хочу отвечать"
       }
      },
      {
       "type": "radiogroup",
       "name": "Q03",
       "title": "Q3. Пожалуйста, отметьте город, в котором Вы постоянно проживаете не менее 6 мес. в году:",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Москва"
        },
        {
         "value": "2",
         "text": "Санкт-Петербург"
        },
        {
         "value": "3",
         "text": "Казань"
        },
        {
         "value": "4",
         "text": "Нижний Новгород"
        },
        {
         "value": "5",
         "text": "Самара"
        },
        {
         "value": "6",
         "text": "Уфа"
        },
        {
         "value": "7",
         "text": "Ростов-на-Дону"
        },
        {
         "value": "8",
         "text": "Волгоград"
        },
        {
         "value": "9",
         "text": "Краснодар"
        },
        {
         "value": "10",
         "text": "Воронеж"
        },
        {
         "value": "11",
         "text": "Екатеринбург"
        },
        {
         "value": "12",
         "text": "Челябинск"
        },
        {
         "value": "13",
         "text": "Пермь"
        },
        {
         "value": "14",
         "text": "Новосибирск"
        },
        {
         "value": "15",
         "text": "Красноярск"
        },
        {
         "value": "16",
         "text": "Омск"
        },
        {
         "value": "98",
         "text": "Другой город"
        }
       ]
      },
      {
       "type": "matrix",
       "name": "Q04",
       "title": "Q4. Скажите, пожалуйста, отмечаются ли у Вас следующие проблемы со здоровьем? И если да, то поставлен ли диагноз этого заболевания.",
       "hideNumber": true,
       "isRequired": true,
       "columns": [
        {
         "value": "1",
         "text": "У меня нет этого заболевания"
        },
        {
         "value": "2",
         "text": "Есть симптоматика заболевания, диагноз врачом НЕ поставлен"
        },
        {
         "value": "3",
         "text": "Есть заболевание, диагноз поставлен врачом"
        }
       ],
       "rows": [
        {
         "value": "1",
         "text": "Сахарный диабет"
        },
        {
         "value": "2",
         "text": "Гипотиреоз"
        },
        {
         "value": "3",
         "text": "Подагра"
        },
        {
         "value": "4",
         "text": "Почечная недостаточность, нарушение работы почек "
        },
        {
         "value": "5",
         "text": "Мочекаменная болезнь"
        },
        {
         "value": "6",
         "text": "Сердечно-сосудистые заболевания "
        },
        {
         "value": "7",
         "text": "Лишний вес"
        },
        {
         "value": "8",
         "text": "Ревматоидный артрит"
        }
       ],
       "rowsOrder": "random",
       "isAllRowRequired": true
      },
      {
       "type": "radiogroup",
       "name": "Q05",
       "title": "Q5. Как давно Вам был поставлен диагноз подагра? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Менее года назад"
        },
        {
         "value": "2",
         "text": "1-2 года назад"
        },
        {
         "value": "3",
         "text": "3-5 лет назад"
        },
        {
         "value": "4",
         "text": "6-10 лет назад"
        },
        {
         "value": "5",
         "text": "Более 10 лет назад (2012 год и раньше)"
        }
       ]
      },
      {
       "type": "radiogroup",
       "name": "Q06",
       "title": "Q6. Как часто у Вас бывают приступы подагры?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "2 раза в год и чаще"
        },
        {
         "value": "2",
         "text": "1 раз в год"
        },
        {
         "value": "3",
         "text": "Реже раза в год"
        },
        {
         "value": "4",
         "text": "Был однократный приступ, и он более не повторялся"
        }
       ]
      },
      {
       "type": "rating",
       "name": "Q07",
       "title": "Q7. Оцените, пожалуйста, насколько Вы в целом обеспокоены заболеванием ПОДАГРА – имеется ввиду тяжесть заболевания, частота приступов, влияние на Ваш образ жизни и пр. При ответе используйте шкалу от 1 до 10, где оценка 1 – Совсем не обеспокоен (-а), 10 – Очень сильно обеспокоен (-а).",
       "hideNumber": true,
       "isRequired": true,
       "rateCount": 10,
       "rateMax": 10,
       "minRateDescription": "1 - Совсем не обеспокоен (-а)",
       "maxRateDescription": "10 - Очень сильно обеспокоена (-а)",
       "displayRateDescriptionsAsExtremeItems": true
      },
      {
       "type": "html",
       "name": "Q00",
       "hideNumber": true,
       "html": "<table><body><row><td></td><td style='padding:0px'><b>Далее мы зададим Вам несколько вопросов о том периоде, <u>когда Вы впервые столкнулись с самыми первыми симптомами и проявлениями подагры</u>, но при этом еще не знали, что это подагра, и диагноз подагра не был поставлен.</b><a></a></td></row></body></table>"
      },
      {
       "type": "matrix",
       "name": "Q08",
       "title": "Q8. Вспомните, пожалуйста, ситуацию, когда симптомы и проявления подагры у Вас появились впервые, и Вы не знали, что это подагра. Оцените по 10-балльной шкале, насколько в тот момент эти симптомы были выражены.  Оценка 1 – Симптома не было совсем, оценка 10 – Симптом был очень сильно выражен.",
       "hideNumber": true,
       "isRequired": true,
       "columns": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10"
       ],
       "rows": [
        {
         "value": "1",
         "text": "Боль"
        },
        {
         "value": "2",
         "text": "Изменение цвета, температуры кожи"
        },
        {
         "value": "3",
         "text": "Нарушение подвижности сустава"
        },
        {
         "value": "4",
         "text": "Повышенная чувствительность"
        },
        {
         "value": "5",
         "text": "Припухлость, отёчность"
        },
        {
         "value": "6",
         "text": "Изменение формы сустава, конечности"
        },
        {
         "value": "7",
         "text": "Появление подкожных уплотнений (тофусов)"
        }
       ],
       "isAllRowRequired": true
      },
      {
       "type": "radiogroup",
       "name": "Q09",
       "title": "Q9. Вспомните, пожалуйста, тот момент, когда у Вас появились первые симптомы, но Вы еще не знали, что это подагра, тогда Вы подумали, что это…?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Какой-то ушиб, травма"
        },
        {
         "value": "2",
         "text": "Случайное воспаление"
        },
        {
         "value": "3",
         "text": "Опухоль / онкология"
        },
        {
         "value": "4",
         "text": "«Шишка» из-за узкой неудобной обуви"
        },
        {
         "value": "5",
         "text": "Артрит обострился"
        },
        {
         "value": "6",
         "text": "Непонятно, что со мной случилось"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другое (запишите)"
      },
      {
       "type": "checkbox",
       "name": "Q10",
       "title": "Q10. Когда у Вас появились первые симптомы, но Вы еще не знали, что это подагра, что Вы подумали в тот момент, надо сделать? Отметьте не более 4-х основных вариантов.",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Надо снять боль, принять болеутоляющее"
        },
        {
         "value": "2",
         "text": "Надо помазать мазью, наложить компресс"
        },
        {
         "value": "3",
         "text": "Надо принять противовоспалительное средство"
        },
        {
         "value": "4",
         "text": "Надо сходить к врачу"
        },
        {
         "value": "5",
         "text": "Надо изучить информацию в интернете"
        },
        {
         "value": "6",
         "text": "Надо узнать информацию у родственников, друзей, коллег"
        },
        {
         "value": "7",
         "text": "Со мной такое впервые, не знаю, что делать"
        }
       ],
       "choicesOrder": "random",
       "separateSpecialChoices": true,
       "showOtherItem": true,
       "showNoneItem": true,
       "noneText": "Ничего не надо делать, само пройдет",
       "otherText": "Другое (запишите)",
       "maxSelectedChoices": 4
      },
      {
       "type": "checkbox",
       "name": "Q11",
       "title": "Q11. Какие основные чувства Вы испытывали в период от появления первых симптомов – до момента постановки диагноза «подагра»? Выберите не более 5 вариантов ответов.",
       "hideNumber": true,
       "isRequired": true,
       "commentText": "Другое (укажите)",
       "choices": [
        {
         "value": "1",
         "text": "Тревога, напряжение, беспокойство"
        },
        {
         "value": "2",
         "text": "Страх, испуг"
        },
        {
         "value": "3",
         "text": "Замешательство, растерянность"
        },
        {
         "value": "4",
         "text": "Неуверенность"
        },
        {
         "value": "5",
         "text": "Волнение"
        },
        {
         "value": "6",
         "text": "Опасение"
        },
        {
         "value": "7",
         "text": "Обреченность"
        },
        {
         "value": "8",
         "text": "Спокойствие"
        },
        {
         "value": "9",
         "text": "Непонимание, что делать"
        },
        {
         "value": "10",
         "text": "Не было никаких особых переживаний, думала (-а), что это временное недомогание"
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "showNoneItem": true,
       "noneText": "Не разобрался (-ась) в своих чувствах",
       "otherText": "Другое (укажите)",
       "maxSelectedChoices": 5
      },
      {
       "type": "radiogroup",
       "name": "Q12",
       "title": "Q12. Вспомните тот момент, когда у Вас впервые появились симптомы подагры (Вы еще не знали, что это подагра) - как долго Вы ждали прежде, чем начали решать проблему?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "При первых же проявлениях симптомов, в первые 6 часов"
        },
        {
         "value": "2",
         "text": "В течение первых суток (в первые 7-24 часа)"
        },
        {
         "value": "3",
         "text": "Через 1 сутки (примерно через 24 часа)"
        },
        {
         "value": "4",
         "text": "Через 2 суток (примерно через 48 часов)"
        },
        {
         "value": "5",
         "text": "Через 3 суток и больше (после 72 часов)"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другое (запишите)"
      },
      {
       "type": "checkbox",
       "name": "Q13",
       "title": "Q13. Вспомните, пожалуйста, какие действия Вы предприняли при самом первом появлении симптомов подагры? Отметьте не более 7 вариантов.",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Искал(-а) в интернете информацию о том, какое это может быть заболевание, исходя из моих симптомов"
        },
        {
         "value": "2",
         "text": "Спросил(-а) родственников, друзей, знакомых (не врачей)"
        },
        {
         "value": "3",
         "text": "Спросил(-а) знакомых врачей"
        },
        {
         "value": "4",
         "text": "Пошел(-ла) в аптеку за лекарством / консультацией фармацевта"
        },
        {
         "value": "5",
         "text": "Записался (-ась) на прием к врачу"
        },
        {
         "value": "6",
         "text": "Проконсультировался (-ась) по телефону с врачом (бесплатная мед. консультация по номеру 112 или телемедицина в коммерческих клиниках)"
        },
        {
         "value": "7",
         "text": "Попробовал (-а) полечиться самостоятельно"
        },
        {
         "value": "98",
         "text": "Другое"
        }
       ],
       "otherText": "Другое / запишите",
       "maxSelectedChoices": 7
      },
      {
       "type": "text",
       "name": "Q13a",
       "visibleIf": "{Q13} contains 98",
       "title": "Q13a. Вы отметили в предыдущем вопросе, что при самом первом появлении симптомов подагры Вы предприняли и другое действие . Пожалуйста опишите какое?",
       "hideNumber": true,
       "isRequired": true
      },
      {
       "type": "ranking",
       "name": "Q14",
       "visibleIf": "{Q13} allof [1, 2] or {Q13} allof [1, 3] or {Q13} allof [1, 4] or {Q13} allof [1, 5] or {Q13} allof [1, 6] or {Q13} allof [1, 7] or {Q13} allof [1, 98] or {Q13} allof [2, 3] or {Q13} allof [2, 4] or {Q13} allof [2, 5] or {Q13} allof [2, 6] or {Q13} allof [2, 7] or {Q13} allof [2, 98] or {Q13} allof [3, 4] or {Q13} allof [3, 5] or {Q13} allof [3, 6] or {Q13} allof [3, 7] or {Q13} allof [3, 98] or {Q13} allof [4, 5] or {Q13} allof [4, 6] or {Q13} allof [4, 7] or {Q13} allof [4, 98] or {Q13} allof [5, 6] or {Q13} allof [5, 7] or {Q13} allof [5, 98] or {Q13} allof [6, 7] or {Q13} allof [6, 98] or {Q13} allof [7, 98]",
       "title": "Q14. Теперь расположите эти действия в том порядке, в котором Вы их совершали – на 1-е место поставьте действие, которое выполнили первым, на 2-е – следующее по порядку действие и т.д.  На последнее место – наименее важную из выбранных характеристик.  Вы можете перетаскивать варианты ответа по очерёдности и менять их местами при необходимости, чтобы выстроить в нужном порядке. Для этого, нажмите мышкой на нужный вариант, удерживая его и смещая вверх-вниз на нужную позицию в рейтинге",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Искал(-а) в интернете информацию о том, какое это может быть заболевание, исходя из моих симптомов"
        },
        {
         "value": "2",
         "text": "Спросил(-а) родственников, друзей, знакомых (не врачей)"
        },
        {
         "value": "3",
         "text": "Спросил(-а) знакомых врачей"
        },
        {
         "value": "4",
         "text": "Пошел(-ла) в аптеку за лекарством / консультацией фармацевта"
        },
        {
         "value": "5",
         "text": "Записался (-ась) на прием к врачу"
        },
        {
         "value": "6",
         "text": "Проконсультировался (-ась) по телефону с врачом (бесплатная мед. консультация по номеру 112 или телемедицина в коммерческих клиниках)"
        },
        {
         "value": "7",
         "text": "Попробовал (-а) полечиться самостоятельно"
        },
        {
         "value": "98",
         "text": "{Q13a}"
        }
       ],
       "choicesVisibleIf": "{Q13} contains {item}",
       "choicesEnableIf": "{{Q13} contains {item}"
      },
      {
       "type": "radiogroup",
       "name": "Q15",
       "title": "Q15. Какое из этих двух утверждений в большей степени описывает Вашу ситуацию, когда симптомы подагры проявились у Вас впервые (но Вы еще не знали, что это подагра)?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "При самых первых проявлениях подагры я практически сразу же обратился (-ась) к врачу"
        },
        {
         "value": "2",
         "text": "При самых первых проявлениях подагры я попробовал (-а) вначале самостоятельно справиться с проблемой "
        }
       ]
      },
      {
       "type": "checkbox",
       "name": "Q16",
       "visibleIf": "{Q15} <> 1",
       "title": "Q16. Что Вы предприняли самостоятельно, без обращения к врачу, при самом первом проявлении подагры? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Сделал(а) компресс (спиртовой, согревающий, охлаждающий и т.п.)"
        },
        {
         "value": "2",
         "text": "Намазал(а) мазью, кремом, гелем"
        },
        {
         "value": "3",
         "text": "Принял(а) противовоспалительный препарат"
        },
        {
         "value": "4",
         "text": "Принял(а) болеутоляющее / анальгетик"
        },
        {
         "value": "5",
         "text": "Принял(а) другой препарат (не противовоспалительный, и не обезболивающий)"
        }
       ],
       "separateSpecialChoices": true,
       "showOtherItem": true,
       "showNoneItem": true,
       "noneText": "Ничего не стал(а) делать, решил(а), что само пройдет",
       "otherText": "Другое (укажите)"
      },
      {
       "type": "multipletext",
       "name": "Q17",
       "visibleIf": "{Q15} <> 1 and {Q16} <> [1, 99, 'other'] and {Q16} anyof [2, 3, 4, 5]",
       "title": "Q17. Какими лекарственными средствами Вы воспользовались? Название каждого препарата / средства впишите в отдельную строку.",
       "hideNumber": true,
       "isRequired": true,
       "items": [
        {
         "name": "1"
        },
        {
         "name": "2"
        },
        {
         "name": "3"
        },
        {
         "name": "4"
        },
        {
         "name": "5"
        },
        {
         "name": "6"
        },
        {
         "name": "7"
        },
        {
         "name": "8"
        },
        {
         "name": "9"
        },
        {
         "name": "10"
        }
       ]
      },
      {
       "type": "checkbox",
       "name": "Q18",
       "visibleIf": "{Q15} <> 1 and ({Q16} <> 2 or {Q16} <>3 or {Q16} <>4 or {Q16} <>5) or {Q16} anyof [1, 99, 'other']",
       "title": "Q18. Какие были основные причины, что Вы решили сначала лечиться самостоятельно, а не обратиться сразу к врачу при появлении первых симптомов подагры (Вы еще не знали, что это подагра)? Выберите не более 3-х основных ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Желание поскорее избавиться от боли, снять острую боль"
        },
        {
         "value": "2",
         "text": "Не было возможности попасть к нужному врачу быстро, нужно записываться сильно заранее"
        },
        {
         "value": "3",
         "text": "Не видел(-а) смысла обращаться к врачам, все можно найти в интернете или других источниках информации"
        },
        {
         "value": "4",
         "text": "У меня нет времени ходить по врачам"
        },
        {
         "value": "5",
         "text": "Не придал(-а) значения боли, такое иногда случается, обходился (-ась) своими силами"
        },
        {
         "value": "6",
         "text": "Не знал (-а), к кому / какому врачу обратиться"
        },
        {
         "value": "7",
         "text": "Не верю врачам, они только деньги требуют"
        }
       ],
       "separateSpecialChoices": true,
       "showOtherItem": true,
       "showNoneItem": true,
       "noneText": {
        "ru": "Не помню"
       },
       "otherText": "Другое (укажите):",
       "maxSelectedChoices": 3
      },
      {
       "type": "radiogroup",
       "name": "Q19",
       "visibleIf": "(({Q16} <> 2 or {Q16} <>3 or {Q16} <>4 or {Q16} <>5) or {Q16} anyof [1, 99, 'other']) and {Q18} anyof [1, 2, 3, 4, 5, 6, 7]",
       "title": "Q19. После Вашего самолечения симптомы подагры прошли?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Да, симптомы прошли "
        },
        {
         "value": "2",
         "text": "Нет, не прошли  "
        }
       ]
      },
      {
       "type": "radiogroup",
       "name": "Q20",
       "visibleIf": "{Q19} = 2",
       "title": "Q20. После того, как Вам НЕ удалось самостоятельно избавиться от симптомов подагры, Вы обратились к врачу?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Да, обратился (-ась) "
        },
        {
         "value": "2",
         "text": "Нет, не обратился (-ась) "
        }
       ]
      },
      {
       "type": "radiogroup",
       "name": "Q21",
       "visibleIf": "{Q19} = 2 and {Q20} = 1",
       "title": "Q21. Что послужило основной причиной для Вашего обращения к врачу? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": {
          "default": "Боль не проходила долгое время",
          "ru": "Боль не проходила"
         }
        },
        {
         "value": "2",
         "text": {
          "default": "Мои обычные способы снятия боли не помогали",
          "ru": "Боль, покраснение, отек"
         }
        },
        {
         "value": "3",
         "text": {
          "default": "Боль начала нарастать",
          "ru": "Боль нарастала"
         }
        }
       ],
       "choicesOrder": "random",
       "separateSpecialChoices": true,
       "showOtherItem": true,
       "otherText": "Другое / укажите"
      },
      {
       "type": "radiogroup",
       "name": "Q22",
       "visibleIf": "{Q19} =1 or {Q20} = 2",
       "title": "Q22. После первого эпизода подагры почему Вы в дальнейшем все-таки обратились к врачу? Какая основная причина?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Боль в какой-то момент опять вернулась"
        },
        {
         "value": "2",
         "text": {
          "default": "Направили после медосмотра, диспансеризации ",
          "ru": "Направили после медосмотра, диспансеризации"
         }
        },
        {
         "value": "3",
         "text": "Плохие результаты анализов (повышенная мочевая кислота) – направили на обследования во время диспансеризации / визита к врачу по другому поводу"
        },
        {
         "value": "4",
         "text": "Пришел (-ла) к врачу по другой причине, другому заболеванию, он меня направил к специалисту по подагре"
        },
        {
         "value": "5",
         "text": "Симптомы стали возвращаться с определенной периодичностью"
        }
       ],
       "separateSpecialChoices": true,
       "showOtherItem": true,
       "otherText": "Другое (укажите)"
      },
      {
       "type": "html",
       "name": "Q23a",
       "hideNumber": true,
       "html": "<table><body><row><td></td><td style='padding:0px'><b>Теперь мы зададим Вам несколько вопросов о диагностике и лечении подагры.</b>  <a></a></td></row></body></table>"
      },
      {
       "type": "radiogroup",
       "name": "Q23",
       "visibleIf": "{Q15} = 1 or {Q15} = 2 or {Q21} anyof [1, 2, 3, 'other'] ",
       "title": "Q23. Сколько прошло времени с того момента, когда Вы впервые ощутили жалобы на здоровье (которые потом оказались симптомами подагры), ДО Вашего первого визита к врачу по поводу этих симптомов? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "1-2 дня"
        },
        {
         "value": "2",
         "text": "3-5 дней"
        },
        {
         "value": "3",
         "text": "6-7 дней"
        },
        {
         "value": "4",
         "text": "8-10 дней"
        },
        {
         "value": "5",
         "text": "11-14 дней"
        },
        {
         "value": "6",
         "text": "15-30 дней"
        },
        {
         "value": "7",
         "text": "От месяца до полугода"
        },
        {
         "value": "8",
         "text": "От полугода до года"
        },
        {
         "value": "9",
         "text": "Более года"
        },
        {
         "value": "99",
         "text": "Не помню"
        }
       ]
      },
      {
       "type": "radiogroup",
       "name": "Q24",
       "title": "Q24. В какое лечебное учреждение Вы впервые обратились, когда решили, что нужна помощь врача при симптомах заболевания (которые потом оказались подагрой)? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "В поликлинику / клинико-диагностический центр по месту жительства (по ОМС)"
        },
        {
         "value": "2",
         "text": "В платную клинику / медицинский центр (по ДМС / за деньги)"
        },
        {
         "value": "3",
         "text": "В травмопункт "
        },
        {
         "value": "4",
         "text": "В скорую помощь"
        },
        {
         "value": "5",
         "text": "Бесплатная телемедицина"
        },
        {
         "value": "6",
         "text": "Платная телемедицина"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другое /уточните/"
      },
      {
       "type": "radiogroup",
       "name": "Q25",
       "title": "Q25. К какому врачу Вы первоначально обратились при проявлениях заболевания (которые потом оказались симптомами подагры)?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Терапевт"
        },
        {
         "value": "2",
         "text": "Хирург"
        },
        {
         "value": "3",
         "text": "Ревматолог"
        },
        {
         "value": "4",
         "text": "Травматолог"
        },
        {
         "value": "5",
         "text": "Эндокринолог"
        },
        {
         "value": "6",
         "text": "Нефролог"
        },
        {
         "value": "7",
         "text": "Уролог"
        }
       ],
       "choicesOrder": "random",
       "separateSpecialChoices": true,
       "showOtherItem": true,
       "otherText": "Другой врач (укажите СПЕЦИАЛЬНОСТЬ):"
      },
      {
       "type": "radiogroup",
       "name": "Q26",
       "title": "Q26. Что рекомендовал врач, к которому Вы первоначально обратились с проявлениями заболевания (которые потом оказались симптомами подагры)?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Сразу направил (-а) к другому врачу, рекомендаций никаких не давал"
        },
        {
         "value": "2",
         "text": "Дал рекомендации по лечению и направил к другому специалисту для дальнейшего наблюдения"
        },
        {
         "value": "3",
         "text": "Дал направление на анализы и обследования, не давал никаких рекомендаций по лечению ДО результатов обследования, назначил повторную консультацию"
        },
        {
         "value": "4",
         "text": "Дал рекомендации по лечению, на анализы и обследования НЕ направлял, назначил повторную консультацию"
        },
        {
         "value": "5",
         "text": "Направил на анализы и обследования, сразу дал рекомендации по лечению, назначил повторную консультацию "
        }
       ],
       "showOtherItem": true,
       "otherText": "Другое /уточните/"
      },
      {
       "type": "radiogroup",
       "name": "Q27",
       "visibleIf": "{Q26} = 1 or {Q26} = 2",
       "title": "Q27. К какому врачу Вас перенаправил врач, к которому Вы обратились впервые по поводу симптомов подагры? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Терапевт"
        },
        {
         "value": "2",
         "text": "Хирург"
        },
        {
         "value": "3",
         "text": "Ревматолог"
        },
        {
         "value": "4",
         "text": "Травматолог"
        },
        {
         "value": "5",
         "text": "Эндокринолог"
        },
        {
         "value": "6",
         "text": "Нефролог"
        },
        {
         "value": "7",
         "text": "Уролог"
        },
        {
         "value": "99",
         "text": "Не помню"
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "otherText": "Другой специалист (укажите специальность):"
      },
      {
       "type": "checkbox",
       "name": "Q28",
       "title": "Q28. Каких еще врачей Вы посетили с жалобами и симптомами относительно подагры, прежде чем Вам был поставлен диагноз Подагра? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Терапевт"
        },
        {
         "value": "2",
         "text": "Хирург"
        },
        {
         "value": "3",
         "text": "Ревматолог"
        },
        {
         "value": "4",
         "text": "Травматолог"
        },
        {
         "value": "5",
         "text": "Эндокринолог"
        },
        {
         "value": "6",
         "text": "Нефролог"
        },
        {
         "value": "7",
         "text": "Уролог"
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "showNoneItem": true,
       "noneText": "Больше никаких врачей не посещал (-а) / Не помню",
       "otherText": "Другой специалист (укажите специальность):"
      },
      {
       "type": "checkbox",
       "name": "Q29",
       "title": "Q29. Укажите, на какие анализы и обследования Вас направили для выявления / подтверждения диагноза Подагра? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Анализы крови, в том числе с определением уровня мочевой кислоты"
        },
        {
         "value": "2",
         "text": "Анализы крови, но БЕЗ определения уровня мочевой кислоты"
        },
        {
         "value": "3",
         "text": "Анализ мочи (как общей, так и суточной)"
        },
        {
         "value": "4",
         "text": "Пункция жидкости из пораженного сустава"
        },
        {
         "value": "5",
         "text": "УЗИ пораженных суставов"
        },
        {
         "value": "6",
         "text": "МРТ / КТ / рентген"
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "otherText": "Другое (укажите, что):"
      },
      {
       "type": "radiogroup",
       "name": "Q30",
       "visibleIf": "{Q26} = 1 or {Q26} = 3",
       "title": "Q30. Какой врач назначил Вам первоначальное лечение ДО официальной постановки диагноза подагра? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Терапевт"
        },
        {
         "value": "2",
         "text": "Хирург"
        },
        {
         "value": "3",
         "text": "Ревматолог"
        },
        {
         "value": "4",
         "text": "Травматолог"
        },
        {
         "value": "5",
         "text": "Эндокринолог"
        },
        {
         "value": "6",
         "text": "Нефролог"
        },
        {
         "value": "7",
         "text": "Уролог"
        },
        {
         "value": "99",
         "text": "Не назначали никакого лечения ДО постановки диагноза Подагра "
        }
       ],
       "choicesFromQuestionMode": "unselected",
       "choicesOrder": "random",
       "showOtherItem": true,
       "otherText": "Другой специалист (укажите специальность):"
      },
      {
       "type": "matrix",
       "name": "Q31",
       "visibleIf": "{Q30} <> 99",
       "title": {
        "default": "Q31. Какие лекарственные препараты Вам были назначены в качестве первоначального лечения ДО официальной постановки диагноза подагра?\nЕсли препарат был назначен (! обратите внимание на разную дозировку), то укажите, пожалуйста, длительность его приема. \n",
        "ru": "Q31. Какие лекарственные препараты Вам были назначены в качестве первоначального лечения ДО официальной постановки диагноза подагра? Если препарат был назначен (! обратите внимание на разную дозировку), то укажите, пожалуйста, длительность его приема. ОБРАТИТЕ ВНИМАНИЕ, ЧТО НЕОБХОДИМО ЗАПОЛНИТЬ КАЖДУЮ СТРОЧКУ В ТАБЛИЦЕ"
       },
       "hideNumber": true,
       "columns": [
        {
         "value": "1",
         "text": "Менее недели"
        },
        {
         "value": "2",
         "text": "1 неделю"
        },
        {
         "value": "3",
         "text": "2 недели"
        },
        {
         "value": "4",
         "text": "3 недели"
        },
        {
         "value": "5",
         "text": "4 недели"
        },
        {
         "value": "6",
         "text": "Более месяца"
        },
        {
         "value": "7",
         "text": "Не назначали данный препарат"
        }
       ],
       "rows": [
        {
         "value": "1",
         "text": "Колхицин"
        },
        {
         "value": "2",
         "text": "Противовоспалительное местного применения – мази /гели (Вольтарен или Диклофенак, Кеторол, Капсикам и т.п. )"
        },
        {
         "value": "3",
         "text": "Противовоспалительное в таблетках - Нимесил, Диклофенак, Вольтарен, Аркоксия, Эторикоксиб и т.п."
        },
        {
         "value": "4",
         "text": "Диклофенак в инъекциях"
        },
        {
         "value": "5",
         "text": "Дипроспан в инъекциях"
        },
        {
         "value": "6",
         "text": "Аллопуринол / Милурит"
        },
        {
         "value": "7",
         "text": "Фебуксостат"
        },
        {
         "value": "98",
         "text": " Другое лечение"
        }
       ],
       "isAllRowRequired": true
      },
      {
       "type": "text",
       "name": "Q31a",
       "visibleIf": "{Q31.98} = 1 or {Q31.98} = 2 or {Q31.98} = 3 or {Q31.98} = 4 or {Q31.98} = 5 or {Q31.98} = 6",
       "title": "Вы указали, что Вам было назначено другое лечение, пожалуйста, уточните какое?",
       "hideNumber": true,
       "isRequired": true
      },
      {
       "type": "radiogroup",
       "name": "Q31b",
       "visibleIf": "{Q31.6} = 1 or {Q31.6} = 2 or {Q31.6} = 3 or {Q31.6} = 4 or {Q31.6} = 5 or {Q31.6} = 6",
       "title": "Уточните, какая дозировка Аллопуринола была Вам назначена?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "100 мг"
        },
        {
         "value": "2",
         "text": "200 мг"
        },
        {
         "value": "3",
         "text": "300 мг"
        },
        {
         "value": "4",
         "text": "Более 300 мг"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другая / уточните /"
      },
      {
       "type": "radiogroup",
       "name": "Q31c",
       "visibleIf": "{Q31.7} = 1 or {Q31.7} = 2 or {Q31.7} = 3 or {Q31.7} = 4 or {Q31.7} = 5 or {Q31.7} = 6",
       "title": "Уточните, какая дозировка Фебуксостата была Вам назначена?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "80 мг"
        },
        {
         "value": "2",
         "text": "120 мг"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другая /уточните/"
      },
      {
       "type": "radiogroup",
       "name": "Q32",
       "title": "Q32. В конечном итоге, какой врач поставил Вам диагноз Подагра?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Тот врач, к которому я обратился (-ась) с самого начала по поводу симптомов подагры "
        },
        {
         "value": "2",
         "text": "Врач, к которому меня сразу направили на консультацию после моего самого первого визита "
        },
        {
         "value": "3",
         "text": "Другой врач "
        }
       ]
      },
      {
       "type": "radiogroup",
       "name": "Q33",
       "visibleIf": "{Q32} = 3",
       "title": "Q33. Уточните специальность врача, который поставил Вам диагноз ПОДАГРА? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Терапевт"
        },
        {
         "value": "2",
         "text": "Хирург"
        },
        {
         "value": "3",
         "text": "Ревматолог"
        },
        {
         "value": "4",
         "text": "Травматолог"
        },
        {
         "value": "5",
         "text": "Эндокринолог"
        },
        {
         "value": "6",
         "text": "Нефролог"
        },
        {
         "value": "7",
         "text": "Уролог"
        },
        {
         "value": "99",
         "text": "Не помню"
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "otherText": "Другой специалист (укажите специальность):"
      },
      {
       "type": "radiogroup",
       "name": "Q34",
       "title": "Q34. Ставили ли Вам какие-то другие диагнозы до того, как подтвердилось, что у Вас подагра?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Нет, диагноз подагра был поставлен сразу"
        }
       ],
       "showOtherItem": true,
       "otherText": "Да, ставились другие диагнозы. КАКИЕ? / запишите"
      },
      {
       "type": "radiogroup",
       "name": "Q35",
       "title": "Q35. Сколько прошло времени с момента первых проявлений подагры ДО официальной ПОСТАНОВКИ ДИАГНОЗА? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "1-2 дня"
        },
        {
         "value": "2",
         "text": "3-5 дней"
        },
        {
         "value": "3",
         "text": "6-7 дней"
        },
        {
         "value": "4",
         "text": "8-10 дней"
        },
        {
         "value": "5",
         "text": "11-14 дней"
        },
        {
         "value": "6",
         "text": "15-30 дней"
        },
        {
         "value": "7",
         "text": "От месяца до полугода"
        },
        {
         "value": "8",
         "text": "От полугода до года"
        },
        {
         "value": "9",
         "text": "Более года"
        },
        {
         "value": "99",
         "text": "Не помню"
        }
       ]
      },
      {
       "type": "radiogroup",
       "name": "Q36",
       "title": "Q36. После постановки диагноза, какое утверждение соответствует Вашей ситуации:",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Лечение подагры назначил тот врач, который поставил диагноз ПОДАГРА"
        },
        {
         "value": "2",
         "text": "Лечение подагры назначил другой врач, не тот, который поставил диагноз"
        }
       ]
      },
      {
       "type": "radiogroup",
       "name": "Q37",
       "visibleIf": "{Q36} = 2",
       "title": "Q37. Какой врач назначил Вам лечение подагры, когда уже был поставлен диагноз? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Терапевт"
        },
        {
         "value": "2",
         "text": "Хирург"
        },
        {
         "value": "3",
         "text": "Ревматолог"
        },
        {
         "value": "4",
         "text": "Травматолог"
        },
        {
         "value": "5",
         "text": "Эндокринолог"
        },
        {
         "value": "6",
         "text": "Нефролог"
        },
        {
         "value": "7",
         "text": "Уролог"
        },
        {
         "value": "99",
         "text": "Не помню"
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "otherText": "Другой специалист (укажите специальность):"
      },
      {
       "type": "matrix",
       "name": "Q38",
       "title": {
        "default": "Q38. Какие лекарственные препараты Вам были назначены в качестве первоначального лечения ДО официальной постановки диагноза подагра?\nЕсли препарат был назначен (! обратите внимание на разную дозировку), то укажите, пожалуйста, длительность его приема",
        "ru": "Q38. Какие лекарственные препараты были Вам назначены для лечения подагры сразу ПОСЛЕ постановки диагноза? \nЕсли препарат был назначен (! обратите внимание на разную дозировку), то укажите, пожалуйста, длительность его приема. ОБРАТИТЕ ВНИМАНИЕ, ЧТО НЕОБХОДИМО ЗАПОЛНИТЬ КАЖДУЮ СТРОЧКУ В ТАБЛИЦЕ"
       },
       "hideNumber": true,
       "isRequired": true,
       "columns": [
        {
         "value": "1",
         "text": "Менее недели"
        },
        {
         "value": "2",
         "text": "1 неделю"
        },
        {
         "value": "3",
         "text": "2 недели"
        },
        {
         "value": "4",
         "text": "3 недели"
        },
        {
         "value": "5",
         "text": "4 недели"
        },
        {
         "value": "6",
         "text": "Более месяца"
        },
        {
         "value": "7",
         "text": "Не назначали данный препарат"
        }
       ],
       "rows": [
        {
         "value": "1",
         "text": "Колхицин"
        },
        {
         "value": "2",
         "text": "Противовоспалительное местного применения – мази /гели (Вольтарен или Диклофенак, Кеторол, Капсикам и т.п. )"
        },
        {
         "value": "3",
         "text": "Противовоспалительное в таблетках - Нимесил, Диклофенак, Вольтарен, Аркоксия, Эторикоксиб и т.п."
        },
        {
         "value": "4",
         "text": "Диклофенак в инъекциях"
        },
        {
         "value": "5",
         "text": "Дипроспан в инъекциях"
        },
        {
         "value": "6",
         "text": "Аллопуринол / Милурит"
        },
        {
         "value": "7",
         "text": "Фебуксостат"
        },
        {
         "value": "98",
         "text": " Другое лечение"
        }
       ],
       "isAllRowRequired": true
      },
      {
       "type": "text",
       "name": "Q38a",
       "visibleIf": "{Q38.98} = 1 or {Q38.98} = 2 or {Q38.98} = 3 or {Q38.98} = 4 or {Q38.98} = 5 or {Q38.98} = 6",
       "title": "Q38a. Вы указали, что Вам было назначено другое лечение, пожалуйста, укажите какое?",
       "hideNumber": true
      },
      {
       "type": "radiogroup",
       "name": "Q38b",
       "visibleIf": "{Q38.6} = 1 or {Q38.6} = 2 or {Q38.6} = 3 or {Q38.6} = 4 or {Q38.6} = 5 or {Q38.6} = 6",
       "title": "Уточните, какая дозировка Аллопуринола была Вам назначена?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "100 мг"
        },
        {
         "value": "2",
         "text": "200 мг"
        },
        {
         "value": "3",
         "text": "300 мг"
        },
        {
         "value": "4",
         "text": "Более 300 мг"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другая / уточните /"
      },
      {
       "type": "radiogroup",
       "name": "Q38c",
       "visibleIf": "{Q38.7} = 1 or {Q38.7} = 2 or {Q38.7} = 3 or {Q38.7} = 4 or {Q38.7} = 5 or {Q38.7} = 6",
       "title": "Уточните, какая дозировка Фебуксостата была Вам назначена?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "80 мг"
        },
        {
         "value": "2",
         "text": "120 мг"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другая /уточните/"
      },
      {
       "type": "radiogroup",
       "name": "Q39",
       "visibleIf": "{Q38.1} <> 7 and {Q38.2} <> 7 and {Q38.3} <> 7 and {Q38.4} <> 7 and {Q38.5} <> 7 and {Q38.6} <> 7 and {Q38.7} <> 7 and {Q38.98} <> 7",
       "title": "Q39. Какое из высказываний в наибольшей степени соответствует Вашим действиям, сразу ПОСЛЕ ПОСТАНОВКИ диагноза Подагра?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Я точно следовал (-а) назначенному тогда лечению, даже после прохождения симптомов"
        },
        {
         "value": "2",
         "text": "Я следовал (-а) лечению до момента исчезновения боли, после исчезновения боли прекратил (-а) принимать препараты"
        },
        {
         "value": "3",
         "text": "Я начал (-а) принимать лекарственные препараты, назначенные врачом, но они не помогли, пришлось обращаться к другому врачу"
        },
        {
         "value": "4",
         "text": "Я начал (-а) принимать лекарственные препараты, назначенные врачом, и дополнительно принимал (-а) болеутоляющие, противовоспалительные препараты (врач их не назначал)"
        },
        {
         "value": "5",
         "text": "Я не стал (-а) принимать лекарственные препараты, назначенные врачом, принимал (-а) другие препараты (болеутоляющие, противовоспалительные и другие)"
        },
        {
         "value": "6",
         "text": "Я ничего не делал (-а), перетерпел (-а) боль"
        },
        {
         "value": "7",
         "text": "Я перешел (-ла) на диетическое питание – никакие лекарственные средства не использовал (-а)"
        },
        {
         "value": "99",
         "text": "Не помню"
        }
       ]
      },
      {
       "type": "checkbox",
       "name": "Q40",
       "title": "Q40. Какие основные чувства / эмоции Вы испытывали, когда Вам поставили диагноз Подагра? Выберите не более 5 вариантов ответов.",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Тревога / напряжение / беспокойство"
        },
        {
         "value": "2",
         "text": "Страх / испуг"
        },
        {
         "value": "3",
         "text": "Замешательство"
        },
        {
         "value": "4",
         "text": "Неуверенность"
        },
        {
         "value": "5",
         "text": "Волнение"
        },
        {
         "value": "6",
         "text": "Опасение"
        },
        {
         "value": "7",
         "text": "Обреченность"
        },
        {
         "value": "8",
         "text": "Спокойствие"
        },
        {
         "value": "9",
         "text": "Облегчение"
        },
        {
         "value": "10",
         "text": "Уверенность"
        },
        {
         "value": "11",
         "text": "Расслабление "
        },
        {
         "value": "12",
         "text": "Понимание, что делать"
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "otherText": "Другое (укажите) ",
       "maxSelectedChoices": 5
      },
      {
       "type": "html",
       "name": "Q40a",
       "hideNumber": true,
       "html": "<table><body><row><td></td><td style='padding:0px'><b>Теперь последует несколько вопросов о профилактике подагры.</b>  <a></a></td></row></body></table>"
      },
      {
       "type": "radiogroup",
       "name": "Q41",
       "title": "Q41. На данный момент, какой врач назначает Вам лечение подагры в ситуациях, когда нет ее обострения – для профилактики подагры и/или для снижения уровня мочевой кислоты?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Терапевт"
        },
        {
         "value": "2",
         "text": "Хирург"
        },
        {
         "value": "3",
         "text": "Ревматолог"
        },
        {
         "value": "4",
         "text": "Травматолог"
        },
        {
         "value": "5",
         "text": "Эндокринолог"
        },
        {
         "value": "6",
         "text": "Нефролог"
        },
        {
         "value": "7",
         "text": "Уролог"
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "otherText": "Другой врач / укажите/"
      },
      {
       "type": "radiogroup",
       "name": "Q42",
       "title": "Q42. Какие лекарственные препараты Вам назначил врач в последний раз для профилактики подагры и/или снижения уровня мочевой кислоты (МК)?\nПри ответе, пожалуйста, имейте ввиду, что: 1. АЛЛОПУРИНОЛ включает в себя препараты Милурит, и все препараты с названием Аллопуринол; 2. ФЕБУКСОСТАТ включает в себя препараты Аденурик, Азурикс, Подагрель, Подагрум, Фебуфорт и все препараты с названием Фебуксостат",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Аллопуринол вместе с колхицином"
        },
        {
         "value": "2",
         "text": "Аллопуринол вместе с НПВС"
        },
        {
         "value": "3",
         "text": "Только Аллопуринол "
        },
        {
         "value": "4",
         "text": "Фебуксостат с НПВС"
        },
        {
         "value": "5",
         "text": "Только Фебуксостат"
        },
        {
         "value": "6",
         "text": {
          "default": "Только Фебуксостат1",
          "ru": "Фебуксостат с колхицином"
         }
        }
       ],
       "showOtherItem": true,
       "otherText": "Другое (укажите, что именно):"
      },
      {
       "type": "radiogroup",
       "name": "Q42a",
       "visibleIf": "{Q42} = 1 or {Q42} = 2 or {Q42} = 3",
       "title": "Уточните, какая дозировка Аллопуринола была Вам назначена?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "100 мг"
        },
        {
         "value": "2",
         "text": "200 мг"
        },
        {
         "value": "3",
         "text": "300 мг"
        },
        {
         "value": "4",
         "text": "Более 300 мг"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другая / уточните /"
      },
      {
       "type": "radiogroup",
       "name": "Q42b",
       "visibleIf": "{Q42} = 4 or {Q42} = 5 or {Q42} = 6",
       "title": "Уточните, какая дозировка Фебуксостата была Вам назначена?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "80 мг"
        },
        {
         "value": "2",
         "text": "120 мг"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другая /уточните/"
      },
      {
       "type": "radiogroup",
       "name": "Q43",
       "title": {
        "ru": "Q43. В последний раз при назначении Вам терапии для профилактики подагры и/или снижения уровня мочевой кислоты (МК) врач скорректировал лечение или оставил то лечение, которое было назначено ранее?"
       },
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": {
          "ru": "Врач скорректировал терапию (назначил другой или дополнительный препарат / изменил дозу препарата, который Вы принимали до этого)"
         }
        },
        {
         "value": "2",
         "text": {
          "ru": "Нет, врач назначил такое же лечение, как и в предыдущий раз – не менял препараты и их дозировку "
         }
        }
       ]
      },
      {
       "type": "checkbox",
       "name": "Q44",
       "visibleIf": "{Q43} = 1",
       "title": "Q44. Уточните, как именно врач скорректировал терапию? Отметьте все необходимое. При ответе, пожалуйста, имейте ввиду, что: 1. АЛЛОПУРИНОЛ включает в себя препараты Милурит, и все препараты с названием Аллопуринол; 2. ФЕБУКСОСТАТ включает в себя препараты Аденурик, Азурикс, Подагрель, Подагрум, Фебуфорт и все препараты с названием Фебуксостат",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Увеличил дозу аллопуринола"
        },
        {
         "value": "2",
         "text": "Увеличил дозу фебуксостата"
        },
        {
         "value": "3",
         "text": "Поменял аллопуринол на фебуксостат"
        },
        {
         "value": "4",
         "text": "Добавил колхицин к аллопуринолу"
        },
        {
         "value": "5",
         "text": "Добавил к аллопуринолу НПВС (противовоспалительные препараты – Диклофенак, Вольтарен, Нимесил и пр.) "
        },
        {
         "value": "6",
         "text": "Добавил к фебуксостату НПВС (противовоспалительные препараты – Диклофенак, Вольтарен, Нимесил и пр.) "
        }
       ],
       "showOtherItem": true,
       "otherText": "Другое (укажите, что именно):"
      },
      {
       "type": "radiogroup",
       "name": "Q45",
       "title": "Q45. Какие рекомендации по режиму приема препаратов для профилактики обострений подагры / снижения уровня мочевой кислоты дал Вам врач?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Принимать препараты регулярно"
        },
        {
         "value": "2",
         "text": "Рекомендовал прием препаратов курсами, несколько раз в год"
        },
        {
         "value": "3",
         "text": "Рекомендовал курс, затем контролировать уровень МК, при повышении – посетить врача"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другое /уточнить/"
      },
      {
       "type": "radiogroup",
       "name": "Q46",
       "title": "Q46. Выполняете ли Вы рекомендации врача в отношении продолжительности и графика приема лекарственных препаратов для профилактики обострений подагры / снижения уровня мочевой кислоты?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Да, всегда полностью выполняю рекомендации по графику и продолжительности приема препаратов "
        },
        {
         "value": "2",
         "text": "Когда как, по-разному"
        },
        {
         "value": "3",
         "text": "Очень редко выполняю рекомендации врача относительно приема препаратов для профилактики обострения подагры / снижения уровня мочевой кислоты"
        }
       ]
      },
      {
       "type": "checkbox",
       "name": "Q47",
       "visibleIf": "{Q46} = 2 or {Q46} = 3",
       "title": "Q47. Почему Вы не всегда / редко выполняете рекомендации врача относительно приема препаратов для профилактики обострений подагры? Назовите не более 3-х основных причин.",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Есть назначения других, несочетаемых лекарственных препаратов"
        },
        {
         "value": "2",
         "text": "Главное - это снять боль. Нет боли – нет необходимости постоянно принимать лекарственные препараты, назначенные врачом"
        },
        {
         "value": "3",
         "text": "В течение длительного времени отсутствуют обострения / приступы"
        },
        {
         "value": "4",
         "text": "Чувствую себя не очень хорошо после приема данных лекарственных препаратов"
        },
        {
         "value": "5",
         "text": "Не понимаю, зачем принимать данные лекарственные препараты постоянно, что они дают"
        },
        {
         "value": "6",
         "text": "Забываю постоянно принимать лекарственные препараты, назначенные врачом"
        },
        {
         "value": "7",
         "text": "Принимаю и так много других лекарственных препаратов по другим заболеваниям"
        },
        {
         "value": "8",
         "text": "Не всегда есть возможность купить данные лекарственные препараты вовремя"
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "otherText": "Другое / запишите",
       "maxSelectedChoices": 3
      },
      {
       "type": "matrix",
       "name": "Q48",
       "title": "Q48. Насколько Вы лично согласны с каждым из следующих высказываний относительно лечения и профилактики подагры? ",
       "hideNumber": true,
       "isRequired": true,
       "columns": [
        {
         "value": "1",
         "text": "Полностью согласен (-на)"
        },
        {
         "value": "2",
         "text": "Скорее согласен (-на)"
        },
        {
         "value": "3",
         "text": "Скорее НЕ согласен (-на)"
        },
        {
         "value": "4",
         "text": "Абсолютно НЕ согласен (-на)"
        }
       ],
       "rows": [
        {
         "value": "1",
         "text": "После приступа подагры я начал (-а) более внимательно относиться к своему здоровью -  начал (-а) заниматься спортом, вести более здоровый образ жизни, обращать внимание на питание и пр."
        },
        {
         "value": "2",
         "text": "Я знаю, что важно своевременно принимать лекарственные препараты от подагры, назначенные врачом, иначе результата от их применения не будет"
        },
        {
         "value": "3",
         "text": "Я стараюсь всегда носить с собой препараты для лечения подагры или стараюсь их купить в случае необходимости"
        },
        {
         "value": "4",
         "text": "Я занимаюсь регулярной профилактикой заболевания, и у меня почти не бывает приступов / приступы случаются очень редко"
        },
        {
         "value": "5",
         "text": "Я регулярно (раз в 1-2 месяца) сдаю анализы на уровень мочевой кислоты"
        },
        {
         "value": "6",
         "text": "Я перестал (-а) заниматься профилактикой заболевания, так как она мне не помогает – болезнь прогрессирует"
        },
        {
         "value": "7",
         "text": "Мне очень сложно выполнять рекомендации врача, так как у меня очень напряженный жизненный график"
        },
        {
         "value": "8",
         "text": "Не верю в профилактику подагры – приступы все равно будут"
        },
        {
         "value": "9",
         "text": "Ношу с собой препараты только на случай обострения подагры – обезболивающие и противовоспалительные"
        }
       ],
       "rowsOrder": "random",
       "isAllRowRequired": true
      },
      {
       "type": "radiogroup",
       "name": "Q49",
       "title": "Q49. Как Вы определяете, что необходимо принять лекарственный препарат, назначенный врачом / начать курс лечения от подагры?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Сдаю анализы на уровень мочевой кислоты в организме"
        },
        {
         "value": "2",
         "text": "Принимаю лекарственные препараты / начинаю курс лечения только в момент наступления приступа"
        },
        {
         "value": "3",
         "text": "Просто регулярно прохожу курс терапии в соответствии с рекомендациями врача"
        },
        {
         "value": "4",
         "text": "Когда проявляются малейшие симптомы болезни (зуд, покраснение, боль), не довожу до сильного обострения "
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "otherText": "Другое  /укажите/"
      },
      {
       "type": "radiogroup",
       "name": "Q50",
       "title": "Q50. Как быстро Вы сейчас начинаете лечение при появлении / нарастании симптомов подагры?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "В ближайшие 1-2 часа"
        },
        {
         "value": "2",
         "text": "В течение суток"
        },
        {
         "value": "3",
         "text": "От 2 дней до недели (7 дней)"
        },
        {
         "value": "4",
         "text": "Более чем через неделю"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другое /укажите/"
      },
      {
       "type": "radiogroup",
       "name": "Q51",
       "title": "Q51. В целом Вы соблюдаете диету при подагре, назначенную врачом?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Да, пришлось отказаться от привычного питания, перейти на диетическое и строго соблюдать его "
        },
        {
         "value": "2",
         "text": "В основном соблюдаю диету, но могу иногда от нее отступить "
        },
        {
         "value": "3",
         "text": "Стараюсь соблюдать, но не всегда получается "
        },
        {
         "value": "4",
         "text": "В очень редких случаях соблюдаю (например, сразу после приступа) "
        },
        {
         "value": "5",
         "text": "Нет, не соблюдаю "
        }
       ]
      },
      {
       "type": "checkbox",
       "name": "Q52",
       "visibleIf": "{Q51} = 4 or {Q51} = 5",
       "title": "Q52. Почему Вы НЕ соблюдаете назначенную диету? Выберите не более 3-х основных причин.",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Мне некогда следить за своим питанием"
        },
        {
         "value": "2",
         "text": "Пробовал (-а) раньше соблюдать, диета не дала эффекта"
        },
        {
         "value": "3",
         "text": "Мне проще принять лекарственный препарат при наступлении обострения, чем менять свои привычки"
        },
        {
         "value": "4",
         "text": "Мои родные меня не поддерживают в части ограничений питания"
        },
        {
         "value": "5",
         "text": "Питанием в семье занимаются другие члены семьи, они определяют, чем питаться"
        },
        {
         "value": "6",
         "text": "Я готовлю и слежу за питанием всей семьи. Сложно при этом готовить себе отдельно"
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "otherText": "Другое /укажите/",
       "maxSelectedChoices": 3
      },
      {
       "type": "html",
       "name": "Q52a",
       "hideNumber": true,
       "html": "<table><body><row><td></td><td style='padding:0px'><b>Сейчас Вам будет задан блок вопросов о том, как Вы справляетесь с приступами обострения подагры.</b>  <a></a></td></row></body></table>"
      },
      {
       "type": "radiogroup",
       "name": "Q53",
       "title": "Q53. В каком лечебном учреждении Вы обычно наблюдаетесь по поводу подагры (вне обострений)?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Поликлиника по месту жительства (полис ОМС)"
        },
        {
         "value": "2",
         "text": "Коммерческая клиника / клинико-диагностический центр (платно или по ДМС)"
        },
        {
         "value": "3",
         "text": "Специализированный центр (например, НИИ ревматологии и т.п.)"
        },
        {
         "value": "4",
         "text": "Стационар"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другое /уточните/"
      },
      {
       "type": "radiogroup",
       "name": "Q54",
       "title": "Q54. Если вспомнить Ваш последний приступ обострения подагры, то он проходил у Вас в более тяжелой или более легкой форме по сравнению с предыдущим обострением? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Последний приступ проходил в более легкой форме"
        },
        {
         "value": "2",
         "text": "Тяжесть последнего приступа была практически такая же, как и предыдущего"
        },
        {
         "value": "3",
         "text": "Последний приступ проходил в более тяжелой форме"
        }
       ],
       "showOtherItem": true,
       "otherText": {
        "ru": "Другое / уточните/"
       }
      },
      {
       "type": "rating",
       "name": "Q55",
       "title": "Q55. Насколько последнее обострение подагры было сильным? Оцените, пожалуйста, по 10-балльной шкале, где оценка 1 – Приступ был очень легким, 10 – Очень тяжелый приступ.",
       "hideNumber": true,
       "isRequired": true,
       "rateCount": 10,
       "rateMax": 10,
       "minRateDescription": "1 – Очень легкий приступ",
       "maxRateDescription": "10 – Очень тяжелый приступ",
       "displayRateDescriptionsAsExtremeItems": true
      },
      {
       "type": "radiogroup",
       "name": "Q56",
       "title": "Q56. Когда у Вас было обострение подагры в последний раз, Вы обратились к …",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "… тому же врачу, который назначает Вам лечение подагры вне обострений / назначает препараты для профилактики и / или снижения уровня МК "
        },
        {
         "value": "2",
         "text": "… другому врачу для консультации "
        },
        {
         "value": "3",
         "text": "… в стационар, где обычно прохожу лечение при обострениях (капельницы и т.д.) "
        },
        {
         "value": "4",
         "text": "Не обращался (-ась) к врачу"
        }
       ]
      },
      {
       "type": "radiogroup",
       "name": "Q57",
       "visibleIf": "{Q56} = 4",
       "title": "Q57. В последний раз Вы НЕ обратились к врачу по поводу обострения подагры, что Вы предприняли для снятия приступа?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Использовал (-а) «старую» схему лечения с использованием препаратов для снижения уровня мочевой кислоты, которую раньше назначал врач – без обращения к врачу"
        },
        {
         "value": "2",
         "text": "Принял (-а) только болеутоляющее и / или противовоспалительные препараты, препараты для снижения уровня мочевой кислоты НЕ принимал (-а) – без обращения к врачу"
        },
        {
         "value": "3",
         "text": "Ничего не предпринимал (-а)"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другое (укажите): "
      },
      {
       "type": "radiogroup",
       "name": "Q58",
       "visibleIf": "{Q56} = 2",
       "title": "Q58. К какому другому врачу Вы обратились в последний раз для консультации по поводу приступа / обострения подагры?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Терапевт"
        },
        {
         "value": "2",
         "text": "Хирург"
        },
        {
         "value": "3",
         "text": "Ревматолог"
        },
        {
         "value": "4",
         "text": "Травматолог"
        },
        {
         "value": "5",
         "text": "Эндокринолог"
        },
        {
         "value": "6",
         "text": "Нефролог"
        },
        {
         "value": "7",
         "text": "Уролог"
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "otherText": "Другой специалист (укажите специальность):"
      },
      {
       "type": "radiogroup",
       "name": "Q59",
       "visibleIf": "{Q58} anyof [7, 2, 4, 1, 5, 3, 6, 'other']",
       "title": "Q59. В какое лечебное учреждение Вы обратились в последний раз по поводу приступа / обострения подагры?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Поликлиника по месту жительства (полис ОМС)"
        },
        {
         "value": "2",
         "text": "Коммерческая клиника / клинико-диагностический центр (платно или по ДМС)"
        },
        {
         "value": "3",
         "text": "Специализированный центр (например, НИИ ревматологии и т.п.)"
        },
        {
         "value": "4",
         "text": "Стационар"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другое /уточните/"
      },
      {
       "type": "checkbox",
       "name": "Q60",
       "visibleIf": "{Q56} = 1 or {Q56} = 3 or {Q59} anyof [1, 2, 3, 4, 'other'] or {Q57} anyof [1, 2, 3, 'other']",
       "title": "Q60. Какие основные чувства / эмоции Вы испытывали в последний раз во время последнего приступа подагры? Выберите не более 5 вариантов ответов.",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Тревога / напряжение / беспокойство"
        },
        {
         "value": "2",
         "text": "Страх / испуг"
        },
        {
         "value": "3",
         "text": "Замешательство"
        },
        {
         "value": "4",
         "text": "Неуверенность"
        },
        {
         "value": "5",
         "text": "Волнение"
        },
        {
         "value": "6",
         "text": "Опасение"
        },
        {
         "value": "7",
         "text": "Обреченность"
        },
        {
         "value": "8",
         "text": "Спокойствие"
        },
        {
         "value": "9",
         "text": "Облегчение"
        },
        {
         "value": "10",
         "text": "Уверенность"
        },
        {
         "value": "11",
         "text": "Расслабление "
        },
        {
         "value": "12",
         "text": "Понимание, что делать"
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "showNoneItem": true,
       "noneText": "Не могу сказать",
       "otherText": "Другое (укажите) ",
       "maxSelectedChoices": 5
      },
      {
       "type": "matrix",
       "name": "Q61",
       "title": {
        "default": "Q61. Какие лекарственные препараты Вы применяли при последнем приступе подагры? Если препарат был назначен (! обратите внимание на разную дозировку), то укажите, пожалуйста, длительность его приема.",
        "ru": "Q61. Какие лекарственные препараты Вы применяли при последнем приступе подагры? Если препарат был назначен (! ОБРАТИТЕ ВНИМАНИЕ НА РАЗНУЮ ДОЗИРОВКУ), то укажите, пожалуйста, длительность его приема. ОБРАТИТЕ ТАКЖЕ ВНИМАНИЕ, ЧТО НЕОБХОДИМО ЗАПОЛНИТЬ КАЖДУЮ СТРОЧКУ В ТАБЛИЦЕ"
       },
       "hideNumber": true,
       "isRequired": true,
       "columns": [
        {
         "value": "1",
         "text": "Менее недели"
        },
        {
         "value": "2",
         "text": "1 неделю"
        },
        {
         "value": "3",
         "text": "2 недели"
        },
        {
         "value": "4",
         "text": "3 недели"
        },
        {
         "value": "5",
         "text": "4 недели"
        },
        {
         "value": "6",
         "text": "Более месяца"
        },
        {
         "value": "7",
         "text": "Не назначали данный препарат"
        }
       ],
       "rows": [
        {
         "value": "1",
         "text": "Колхицин"
        },
        {
         "value": "2",
         "text": "Противовоспалительное местного применения – мази /гели (Вольтарен или Диклофенак, Кеторол, Капсикам и т.п. )"
        },
        {
         "value": "3",
         "text": "Противовоспалительное в таблетках - Нимесил, Диклофенак, Вольтарен, Аркоксия, Эторикоксиб и т.п."
        },
        {
         "value": "4",
         "text": "Диклофенак в инъекциях"
        },
        {
         "value": "5",
         "text": "Дипроспан в инъекциях"
        },
        {
         "value": "6",
         "text": "Аллопуринол / Милурит"
        },
        {
         "value": "7",
         "text": "Фебуксостат"
        },
        {
         "value": "98",
         "text": " Другое лечение"
        }
       ],
       "isAllRowRequired": true
      },
      {
       "type": "text",
       "name": "Q61a",
       "visibleIf": "{Q61.98} = 1 or {Q61.98} = 2 or {Q61.98} = 3 or {Q61.98} = 4 or {Q61.98} = 5 or {Q61.98} = 6",
       "title": "Вы указали, что Вам было назначено другое лечение, пожалуйста, уточните какое?",
       "hideNumber": true,
       "isRequired": true
      },
      {
       "type": "radiogroup",
       "name": "Q61b",
       "visibleIf": "{Q61.6} = 1 or {Q61.6} = 2 or {Q61.6} = 3 or {Q61.6} = 4 or {Q61.6} = 5 or {Q61.6} = 6",
       "title": "Уточните, какая дозировка Аллопуринола была Вам назначена?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "100 мг"
        },
        {
         "value": "2",
         "text": "200 мг"
        },
        {
         "value": "3",
         "text": "300 мг"
        },
        {
         "value": "4",
         "text": "Более 300 мг"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другая / уточните /"
      },
      {
       "type": "radiogroup",
       "name": "Q61c",
       "visibleIf": "{Q61.7} = 1 or {Q61.7} = 2 or {Q61.7} = 3 or {Q61.7} = 4 or {Q61.7} = 5 or {Q61.7} = 6",
       "title": "Уточните, какая дозировка Фебуксостата была Вам назначена?",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "80 мг"
        },
        {
         "value": "2",
         "text": "120 мг"
        }
       ],
       "showOtherItem": true,
       "otherText": "Другая /уточните/"
      },
      {
       "type": "radiogroup",
       "name": "Q62",
       "title": "Q62. Была ли изменена Ваша схема лечения при последнем приступе подагры? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Да"
        },
        {
         "value": "2",
         "text": "Нет"
        }
       ]
      },
      {
       "type": "checkbox",
       "name": "Q63",
       "visibleIf": "{Q62} = 1",
       "title": "Q63. В связи с чем Вам изменили схему лечения при последнем приступе подагры?\nВыберите не более 3-х самых важных причин.\n",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Были побочные эффекты, плохая переносимость предыдущей схемы лечения"
        },
        {
         "value": "2",
         "text": "На предыдущей схеме не снижался уровень мочевой кислоты так, как это было необходимо"
        },
        {
         "value": "3",
         "text": "Предыдущая схема плохо снимала боль"
        },
        {
         "value": "4",
         "text": "Эффект от лечения наступил не сразу - приступ длился очень долго "
        },
        {
         "value": "5",
         "text": "Жжение, зуд долго не проходили"
        },
        {
         "value": "6",
         "text": "Приступ был очень сильный, лечение очень плохо помогало"
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "otherText": "Другое / укажите",
       "maxSelectedChoices": 3
      },
      {
       "type": "radiogroup",
       "name": "Q64",
       "title": "Q64. Посещаете ли Вы регулярные консультации с лечащим врачом? Если да, то где? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Нет"
        },
        {
         "value": "2",
         "text": "Да, в поликлинике по месту жительства (по ОМС)"
        },
        {
         "value": "3",
         "text": "Да, в медцентре (платно или по ДМС)"
        },
        {
         "value": "4",
         "text": "Да, в специализированном центре (например, НИИ ревматологии)"
        },
        {
         "value": "5",
         "text": "Да, в стационаре"
        }
       ]
      },
      {
       "type": "radiogroup",
       "name": "Q65",
       "title": "Q65. Есть ли у Вас потребность в дальнейших консультациях с лечащим врачом? ",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Да"
        },
        {
         "value": "2",
         "text": "Нет"
        }
       ]
      },
      {
       "type": "checkbox",
       "name": "Q66",
       "title": "Q66. На что Вы обращаете внимание при выборе лекарственного препарата для лечения подагры? Выберите не более 4-х самых важных факторов.",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Личный опыт приема препаратов"
        },
        {
         "value": "2",
         "text": "Рекомендации лечащих врачей"
        },
        {
         "value": "3",
         "text": "Мнение профессионалов в открытых источниках (форумы, обзоры в интернете)"
        },
        {
         "value": "4",
         "text": "Отзывы – мнение других пациентов с подагрой с опытом приема препаратов"
        },
        {
         "value": "5",
         "text": "Рекомендации фармацевтов"
        },
        {
         "value": "6",
         "text": "Мнение медийных специалистов-врачей"
        },
        {
         "value": "7",
         "text": "Мнение родных, друзей, коллег, знакомых"
        },
        {
         "value": "8",
         "text": "Информация и отзывы в социальных сетях и мессенджерах, которым я доверяю"
        },
        {
         "value": "9",
         "text": "Информация о препарате на сайте фармкомпании, в справочниках и пр."
        },
        {
         "value": "10",
         "text": "Стоимость препарата - ищу более дешевые аналоги"
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "otherText": "Другое(укажите):",
       "maxSelectedChoices": 4
      },
      {
       "type": "checkbox",
       "name": "Q67",
       "title": "Q67. Какие характеристики лекарственного препарата для лечения подагры для Вас наиболее важны? Выберите не более 7 самых важных характеристик препарата.",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Отсутствие аллергических реакций на препарат"
        },
        {
         "value": "2",
         "text": "Минимальное количество побочных эффектов, хорошая переносимость"
        },
        {
         "value": "3",
         "text": "Скорость наступления эффекта после приема лекарства"
        },
        {
         "value": "4",
         "text": "Длительность действия препарата"
        },
        {
         "value": "5",
         "text": "Снижение количества приступов"
        },
        {
         "value": "6",
         "text": "Купирование «острого» приступа"
        },
        {
         "value": "7",
         "text": "Снижение уровня мочевой кислоты до нормы"
        },
        {
         "value": "8",
         "text": "Купирование боли при первых симптомах и проявлениях болезни"
        },
        {
         "value": "98",
         "text": "Другое"
        }
       ],
       "choicesOrder": "random",
       "maxSelectedChoices": 7
      },
      {
       "type": "text",
       "name": "Q67a",
       "visibleIf": "{Q67} contains 98",
       "title": "Вы отметили в предыдущем вопросе, что для Вас важны и другие характеристики лекарственного препарата . Пожалуйста опишите какие?",
       "hideNumber": true,
       "isRequired": true
      },
      {
       "type": "ranking",
       "name": "Q68",
       "visibleIf": "{Q67} allof [1, 2] or {Q67} allof [1, 3] or {Q67} allof [1, 4] or {Q67} allof [1, 5] or {Q67} allof [1, 6] or {Q67} allof [1, 7] or {Q67} allof [1, 8] or {Q67} allof [1, 98] or {Q67} allof [2, 3] or {Q67} allof [2, 4] or {Q67} allof [2, 5] or {Q67} allof [2, 6] or {Q67} allof [2, 7] or {Q67} allof [2, 8] or {Q67} allof [2, 98] or {Q67} allof [3, 4] or {Q67} allof [3, 5] or {Q67} allof [3, 6] or {Q67} allof [3, 7] or {Q67} allof [3, 8] or {Q67} allof [3, 98] or {Q67} allof [4, 5] or {Q67} allof [4, 6] or {Q67} allof [4, 7] or {Q67} allof [4, 8] or {Q67} allof [4, 98] or {Q67} allof [5, 6] or {Q67} allof [5, 7] or {Q67} allof [5, 8] or {Q67} allof [5, 98] or {Q67} allof [6, 7] or {Q67} allof [6, 8] or {Q67} allof [6, 98] or {Q67} allof [7, 8] or {Q67} allof [7, 98] or {Q67} allof [8, 98] ",
       "title": "Q68. Теперь расположите выбранные Вами характеристики препарата для лечения подагры в порядке их важности лично для Вас. На 1-е место поставьте самую важную характеристику, на 2-е – следующую по важности и т.д. На последнее место – наименее важную из выбранных характеристик.  Вы можете перетаскивать варианты ответа по очерёдности и менять их местами при необходимости, чтобы выстроить в нужном порядке. Для этого, нажмите мышкой на нужный вариант, удерживая его и смещая вверх-вниз на нужную позицию в рейтинге",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Отсутствие аллергических реакций на препарат"
        },
        {
         "value": "2",
         "text": "Минимальное количество побочных эффектов, хорошая переносимость"
        },
        {
         "value": "3",
         "text": "Скорость наступления эффекта после приема лекарства"
        },
        {
         "value": "4",
         "text": "Длительность действия препарата"
        },
        {
         "value": "5",
         "text": "Снижение количества приступов"
        },
        {
         "value": "6",
         "text": "Купирование «острого» приступа"
        },
        {
         "value": "7",
         "text": "Снижение уровня мочевой кислоты до нормы"
        },
        {
         "value": "8",
         "text": "Купирование боли при первых симптомах и проявлениях болезни"
        },
        {
         "value": "98",
         "text": "{Q67a}"
        }
       ],
       "choicesVisibleIf": "{Q67} contains {item}",
       "choicesEnableIf": "{{Q67} contains {item}"
      },
      {
       "type": "matrix",
       "name": "Q69",
       "title": "Q69. Насколько Вы согласны с каждым из следующих утверждений?",
       "hideNumber": true,
       "isRequired": true,
       "columns": [
        {
         "value": "1",
         "text": "Согласен (-а)"
        },
        {
         "value": "2",
         "text": "Скорее согласен (-а)"
        },
        {
         "value": "3",
         "text": "Скорее НЕ согласен (-а)"
        },
        {
         "value": "4",
         "text": "НЕ согласен (-а)"
        }
       ],
       "rows": [
        {
         "value": "1",
         "text": "Я регулярно ищу новости о лечении заболевания, хочу знать о современных способах лечения, современных препаратах"
        },
        {
         "value": "2",
         "text": "Я спрашиваю у врача о новых способах лечения и новых препаратах"
        },
        {
         "value": "3",
         "text": "Я уверен(а), что врачи в курсе новых способов и препаратов лечения, они всегда предложат лучший вариант "
        },
        {
         "value": "4",
         "text": "Я не интересуюсь информацией о болезни и способах ее лечения, для меня главное – не болеть"
        }
       ],
       "rowsOrder": "random",
       "isAllRowRequired": true
      },
      {
       "type": "checkbox",
       "name": "Q70",
       "title": "Q70. Какой информации Вам НЕ хватает о заболевании ПОДАГРА и его лечении? Выберите не более 3 самых значимых для вас ответов",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "О новых возможностях лечения подагры"
        },
        {
         "value": "2",
         "text": "О врачах, которые лечат подагру"
        },
        {
         "value": "3",
         "text": "О побочных эффектах препаратов и их сочетаемости"
        },
        {
         "value": "5",
         "text": "О причинах заболевания"
        },
        {
         "value": "6",
         "text": "Об эффективных способах лечения"
        }
       ],
       "choicesOrder": "random",
       "showOtherItem": true,
       "showNoneItem": true,
       "noneText": "Всего хватает, информации достаточно",
       "otherText": "Другое / запишите",
       "maxSelectedChoices": 3
      },
      {
       "type": "checkbox",
       "name": "Q71",
       "title": "Q71. Исходя из Вашего опыта, с какими сложностями Вы столкнулись при диагностике, обследовании, лечении или профилактике подагры? Выберите не более 7, самых значимых для Вас.",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Непонятно, к какому врачу обращаться"
        },
        {
         "value": "2",
         "text": "Долгий процесс постановки диагноза"
        },
        {
         "value": "3",
         "text": "Платные анализы и обследования"
        },
        {
         "value": "4",
         "text": "Сложная схема лечения – необходимость титрования (корректировки) дозировки препарата"
        },
        {
         "value": "5",
         "text": "Побочные эффекты лечения"
        },
        {
         "value": "6",
         "text": "Дорогостоящие лекарства"
        },
        {
         "value": "7",
         "text": "Каждый раз приходится искать нового врача"
        },
        {
         "value": "8",
         "text": "Отсутствие постоянного контакта с врачом"
        },
        {
         "value": "9",
         "text": "Усугубление других заболеваний на фоне приема препаратов от подагры"
        },
        {
         "value": "10",
         "text": "Требуется пожизненный прием лекарств"
        },
        {
         "value": "98",
         "text": "Другое"
        }
       ],
       "choicesOrder": "random",
       "maxSelectedChoices": 7
      },
      {
       "type": "text",
       "name": "Q71a",
       "visibleIf": "{Q71} contains 98",
       "title": "Вы отметили в предыдущем вопросе, что Вы cтолкнулись с другими сложностями. Пожалуйста опишите с какими?",
       "hideNumber": true,
       "isRequired": true
      },
      {
       "type": "ranking",
       "name": "Q72",
       "visibleIf": "{Q71} allof [1, 2] or {Q71} allof [1, 3] or {Q71} allof [1, 4] or {Q71} allof [1, 5] or {Q71} allof [1, 6] or {Q71} allof [1, 7] or {Q71} allof [1, 8] or {Q71} allof [1, 9] or {Q71} allof [1, 10] or {Q71} allof [1, 98] or {Q71} allof [2, 3] or {Q71} allof [2, 4] or {Q71} allof [2, 5] or {Q71} allof [2, 6] or {Q71} allof [2, 7] or {Q71} allof [2, 8] or {Q71} allof [2, 9] or {Q71} allof [2, 10] or {Q71} allof [2, 98] or {Q71} allof [3, 4] or {Q71} allof [3, 5] or {Q71} allof [3, 6] or {Q71} allof [3, 7] or {Q71} allof [3, 8] or {Q71} allof [3, 9] or {Q71} allof [3, 10] or {Q71} allof [3, 98] or {Q71} allof [4, 5] or {Q71} allof [4, 6] or {Q71} allof [4, 7] or {Q71} allof [4, 8] or {Q71} allof [4, 9] or {Q71} allof [4, 10] or {Q71} allof [4, 98] or {Q71} allof [5, 6] or {Q71} allof [5, 7] or {Q71} allof [5, 8] or {Q71} allof [5, 9] or {Q71} allof [5, 10] or {Q71} allof [5, 98] or {Q71} allof [6, 7] or {Q71} allof [6, 8] or {Q71} allof [6, 9] or {Q71} allof [6, 10] or {Q71} allof [6, 98] or {Q71} allof [7, 8] or {Q71} allof [7, 9] or {Q71} allof [7, 10] or {Q71} allof [7, 98] or {Q71} allof [8, 9] or {Q71} allof [8, 10] or {Q71} allof [8, 98] or {Q71} allof [9, 10] or {Q71} allof [9, 98] or {Q71} allof [10, 98] ",
       "title": "Q72. В предыдущем вопросе Вы выбрали следующие сложности и проблемы, с которыми сталкивались за все время заболевания подагрой. Расположите их в порядке убывания от самой важной к наименее важной: 1-е место - самая серьезная и значимая для Вас проблема, 2-е место – чуть менее значимая и т.д.  На последнее место – наименее важную из выбранных характеристик.    Вы можете перетаскивать варианты ответа по очерёдности и менять их местами при необходимости, чтобы выстроить в нужном порядке.\nДля этого, нажмите мышкой на нужный вариант, удерживая его и смещая вверх-вниз на нужную позицию в рейтинге",
       "hideNumber": true,
       "isRequired": true,
       "choices": [
        {
         "value": "1",
         "text": "Непонятно, к какому врачу обращаться"
        },
        {
         "value": "2",
         "text": "Долгий процесс постановки диагноза"
        },
        {
         "value": "3",
         "text": "Платные анализы и обследования"
        },
        {
         "value": "4",
         "text": "Сложная схема лечения – необходимость титрования (корректировки) дозировки препарата"
        },
        {
         "value": "5",
         "text": "Побочные эффекты лечения"
        },
        {
         "value": "6",
         "text": "Дорогостоящие лекарства"
        },
        {
         "value": "7",
         "text": "Каждый раз приходится искать нового врача"
        },
        {
         "value": "8",
         "text": "Отсутствие постоянного контакта с врачом"
        },
        {
         "value": "9",
         "text": "Усугубление других заболеваний на фоне приема препаратов от подагры"
        },
        {
         "value": "10",
         "text": "Требуется пожизненный прием лекарств"
        },
        {
         "value": "98",
         "text": "{Q71a}"
        }
       ],
       "choicesVisibleIf": "{Q71} contains {item}",
       "choicesEnableIf": "{{Q71} contains {item}"
      },
      {
       "type": "text",
       "name": "Q73_1",
       "title": "Q73_1. Какая стоимость одной упаковки КОЛХИЦИНА объемом 30 таблеток    является для Вас, настолько высокой, что Вы не станете его покупать?   При ответе учитывайте, что схема приема колхицина следующая: При остром приступе подагры принимают сначала 1 таб. (1 мг колхицина), а затем по 0.5-1.5 мг (0,5-1,5 таб.) через каждые 1-2 ч до ослабления боли. Для купирования приступа достаточно 1 упаковки. Пожалуйста, укажите стоимость в рублях",
       "hideNumber": true,
       "isRequired": true,
       "inputType": "number",
       "min": 1,
       "max": 3000,
       "minErrorText": "Значение не может быть меньше {1}",
       "maxErrorText": "Значение не может быть больше {3000}"
      },
      {
       "type": "text",
       "name": "Q73_2",
       "title": "Q73_2. Какая стоимость одной упаковки КОЛХИЦИНА объемом 30 таблеток является для Вас настолько низкой, что Вы начнете сомневаться в его качестве?   При ответе учитывайте, что схема приема колхицина следующая: \nПри остром приступе подагры принимают сначала 1 таб. (1 мг колхицина), а затем по 0.5-1.5 мг (0,5-1,5 таб.) через каждые 1-2 ч до ослабления боли. Для купирования приступа достаточно 1 упаковки. Пожалуйста, укажите стоимость в рублях",
       "hideNumber": true,
       "isRequired": true,
       "inputType": "number",
       "min": 1,
       "minValueExpression": "1",
       "maxValueExpression": "{Q73_1}",
       "minErrorText": "Значение не может быть меньше {1}",
       "maxErrorText": "Значение не может быть больше {Q73_1}"
      },
      {
       "type": "text",
       "name": "Q73_3",
       "title": "Q73_3. Какая стоимость одной упаковки КОЛХИЦИНА объемом 30 таблеток является для Вас высокой, но Вы все равно его купите при необходимости?   При ответе учитывайте, что схема приема колхицина следующая: При остром приступе подагры принимают сначала 1 таб. (1 мг колхицина), а затем по 0.5-1.5 мг (0,5-1,5 таб.) через каждые 1-2 ч до ослабления боли. Для купирования приступа достаточно 1 упаковки. Пожалуйста, укажите стоимость в рублях",
       "hideNumber": true,
       "isRequired": true,
       "inputType": "number",
       "minValueExpression": "{Q73_2}",
       "maxValueExpression": "{Q73_1}",
       "minErrorText": "Значение не может быть меньше {Q73_2}",
       "maxErrorText": "Значение не может быть больше {Q73_1}"
      },
      {
       "type": "text",
       "name": "Q73_4",
       "title": "Q73_4. Какая стоимость одной упаковки КОЛХИЦИНА объемом 30 таблеток является для Вас выгодной покупкой?   При ответе учитывайте, что схема приема колхицина следующая: При остром приступе подагры принимают сначала 1 таб. (1 мг колхицина), а затем по 0.5-1.5 мг (0,5-1,5 таб.) через каждые 1-2 ч до ослабления боли. Для купирования приступа достаточно 1 упаковки. Пожалуйста, укажите стоимость в рублях",
       "hideNumber": true,
       "isRequired": true,
       "inputType": "number",
       "minValueExpression": "{Q73_2}",
       "maxValueExpression": "{Q73_3}",
       "minErrorText": "Значение не может быть меньше {Q73_2}",
       "maxErrorText": "Значение не может быть больше {Q73_3}"
      }
     ]
    }
   ],
   "triggers": [
    {
     "type": "complete",
     "expression": "{Q02} = 'none' or {Q02} < 30"
    },
    {
     "type": "complete",
     "expression": "{Q03} = 98"
    },
    {
     "type": "complete",
     "expression": "{Q04.3} <> 3"
    },
    {
     "type": "complete",
     "expression": "{Q05} = 1 or {Q05} = 2"
    },
    {
     "type": "complete",
     "expression": "{Q06} = 3 or {Q06} = 4"
    }
   ],
   "calculatedValues": [
    {
     "name": "Q13a",
     "expression": "{Q13a}",
     "includeIntoResult": true
    },
    {
     "name": "Q67a",
     "expression": "{Q67a}",
     "includeIntoResult": true
    },
    {
     "name": "Q71a",
     "expression": "{Q71a}",
     "includeIntoResult": true
    }
   ],
   "navigateToUrl": "https://yandex.ru",
   "navigateToUrlOnCondition": [
    {
     "expression": "{Q02} = 'none' or {Q02} < 30 or {Q03} = 98 or {Q04.3} <> 2 or {Q05} = 1 or {Q05} = 2 or {Q06} = 3 or {Q06} = 4",
     "url": {
      "ru": "https://yandex.ru"
     }
    }
   ],
   "showProgressBar": "top",
   "progressBarType": "questions",
   "questionsOnPageMode": "questionPerPage",
   "showPrevButton": false,
   "widthMode": "responsive"
  };
